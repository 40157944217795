import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {
  ApiComponentsDTO,
  ApiContentDTO,
  ApiContentTypes,
  ApiMethodPathsDTO,
  ApiMethods,
  ApiParameters,
  ApiPathResponse,
  ApiProperties,
  ApiRequestBody,
  ApiRequestBodyDTO,
  ApiSchemasDTO,
  ApiScheme,
  ApiServicesDTO,
  ApiTagDTO,
  DocsObjectParameters,
  DocsRequestBodyDTO,
  DocsResponseBodyDTO,
  DocsServiceDTO,
  In,
  PropertyTypes,
} from '@common/ts/apiFormInterfaces';
import {BACKEND_URL} from '@common/ts/config';
import {MenuOverlaySubOptions} from '@common/ts/interfaces';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {BehaviorSubject} from 'rxjs';

@Injectable()
@UntilDestroy()
export class SwaggerService {

  public services: BehaviorSubject<DocsServiceDTO[]> = new BehaviorSubject([]);
  public menuPages: BehaviorSubject<MenuOverlaySubOptions<string>[]> = new BehaviorSubject([]);

  constructor(public http: HttpClient) {
  }

  private getControllers(tags: ApiTagDTO[], host: string): { menuPages: MenuOverlaySubOptions<string>[]; services: DocsServiceDTO[]; } {
    const menuPages: MenuOverlaySubOptions<string>[] = [],
      services: DocsServiceDTO[] = [];
    for (const {name, description} of tags) {
      menuPages.push({
        options: [],
        title: description,
      });
      services.push({
        name: description,
        id: name,
        host,
        functions: [],
        path: '',
      });
    }
    return {
      menuPages,
      services,
    };
  }

  private getResponseBodies(responses: Record<string, ApiPathResponse>): DocsResponseBodyDTO[] {
    const responseBody: DocsResponseBodyDTO[] = [];
    for (const [name, {description, content}] of Object.entries(responses)) {
      let objectLink = '', responseType: PropertyTypes = null;
      if (content) {
        for (const {schema} of Object.values(content)) {
          if (schema.items?.$ref) {
            objectLink = `docs/objects/${this.getObjectName(schema.items.$ref)}`;
          }
          if (schema.type) {
            responseType = schema.type;
          }
        }
      }
      responseBody.push({
        name,
        description,
        type: responseType,
        objectLink,
      });
    }
    return responseBody;
  }

  private getRequestBodies(parameters: ApiParameters[], requestBody: ApiRequestBody, method: ApiMethods, controller: string,
                           components: ApiComponentsDTO): {
      curl: Record<string, string | Record<string, string>>;
      curlUrl: string;
      request: DocsRequestBodyDTO[];
    } {
    const request: DocsRequestBodyDTO[] = [], curl: Record<string, string | Record<string, string>> = {};
    let curlUrl = '';
    if (parameters) {
      for (const {name, description, required, schema, in: inPlace} of parameters) {
        if (name !== 'details' && name !== 'authenticated') {
          const objectLink = this.getObjectLink(schema);
          request.push({
            allowedValues: this.getAllowedValues(schema.type, schema.enum || []),
            defaultValue: schema.default || null,
            inPlace,
            format: schema.format || null,
            description,
            name,
            objectLink,
            required,
            type: schema.type,
          });
          if (inPlace) {
            curlUrl = this.getCurlUrl(controller, name, inPlace, schema, components, curlUrl);
          } else if (method === 'post' || method === 'put') {
            curl[name] = this.getCurlRequest(schema);
          }
        }
      }
    }
    if (requestBody) {
      this.processRequestBody(requestBody, request, method, curl, components);
    }
    return {
      request,
      curlUrl,
      curl,
    };
  }

  private getCurlUrl(controller: string, name: string, inPlace: In, schema: ApiScheme, components: ApiComponentsDTO,
                     curlUrl: string): string {
    if (!controller.includes(name)) {
      if (inPlace === 'query') {
        if (schema?.$ref) {
          const dto = components.schemas[this.getObjectName(schema.$ref)];
          if (dto) {
            for (const [param, {type}] of Object.entries(dto.properties)) {
              curlUrl = `${this.queryPrefix(curlUrl)}${param}=${type}`;
            }
          }
        } else {
          curlUrl = `${this.queryPrefix(curlUrl)}${name}=${schema.type}`;
        }
      } else if (inPlace === 'path') {
        curlUrl = `${curlUrl}/${name}`;
      }
    }
    return curlUrl;
  }

  private queryPrefix(curlUrl: string): string {
    return curlUrl.length ? `${curlUrl}&` : '?';
  }

  private processRequestBody(requestBody: ApiRequestBody, request: DocsRequestBodyDTO[], method: ApiMethods,
                             curl: Record<string, string | Record<string, string>>, components: ApiComponentsDTO): void {
    for (const {schema} of Object.values(requestBody.content)) {
      const objectLink = this.getObjectLink(schema);
      if (objectLink) {
        request.push({
          allowedValues: this.getAllowedValues(schema?.type, schema.enum || []),
          defaultValue: schema.default || null,
          inPlace: 'body',
          format: schema.format || null,
          description: requestBody.description,
          name: this.getObjectName(schema.$ref),
          objectLink,
          required: requestBody.required,
          type: schema.type,
        });
        if (method === 'post' || method === 'put') {
          this.generateCurlBody(components, schema, curl);
        }
      } else {
        for (const [propertyName, {type, format}] of Object.entries(schema.properties)) {
          request.push({
            allowedValues: this.getAllowedValues(type, []),
            defaultValue: null,
            inPlace: 'body',
            format: format || null,
            description: '',
            name: propertyName,
            objectLink,
            required: schema.required ? schema.required.includes(propertyName) : false,
            type,
          });
          if (method === 'post' || method === 'put') {
            curl[propertyName] = type;
          }
        }
      }
    }
  }

  private generateCurlBody(components: ApiComponentsDTO, schema: ApiScheme,
                           curl: Record<string, string | Record<string, string>>): void {
    const dto = components.schemas[this.getObjectName(schema.$ref)];
    if (dto) {
      for (const [param, {type}] of Object.entries(dto.properties)) {
        curl[param] = type;
      }
    }
  }

  private getObjectLink(schema: ApiScheme): string {
    if (schema?.$ref) {
      return `docs/objects/${this.getObjectName(schema.$ref)}`;
    }
    return '';
  }

  private getCurlRequest(schema: ApiScheme): PropertyTypes | Record<string, string> {
    if (schema?.$ref) {
      const dtoRequest: Record<string, string> = {};
      dtoRequest[this.getObjectName(schema.$ref)] = schema.type;
      return dtoRequest;
    }
    return schema.type;
  }

  private generatePaths(basePath: string, paths: Record<string, ApiMethodPathsDTO>, services: DocsServiceDTO[],
                        menuPages: MenuOverlaySubOptions<string>[], components: ApiComponentsDTO): void {
    for (const [controller, methods] of Object.entries(paths)) {
      for (const [method, {operationId, tags, description, summary, parameters, responses, requestBody}] of Object.entries(methods)) {
        const findIndex = services.findIndex(({id}) => tags.includes(id));
        if (findIndex !== -1) {
          const url = `docs/${services[findIndex].id}/${operationId}`,
            responseBody = this.getResponseBodies(responses),
            {request, curl, curlUrl} = this.getRequestBodies(parameters, requestBody, method as ApiMethods, controller, components);
          services[findIndex].path = services[findIndex].path || url;
          services[findIndex].functions.push({
            method: method.toUpperCase() as ApiMethods,
            id: operationId,
            name: controller,
            description: description || summary,
            path: url,
            responseBody,
            requestBody: request,
            sampleCode: this.generateCurl(`${basePath}${controller}${curlUrl}`, method.toUpperCase() as ApiMethods,
              {
                'Content-type': 'application/json; charset=UTF-8',
                Authorization: 'Bearer YOUR API SECRET',
              }, Object.keys(curl).length ? curl : undefined),
          });
          menuPages[findIndex].options.push({
            name: `<span class="method ${method.toUpperCase()}">${method.toUpperCase()}</span> ${controller}`,
            url,
          });
        }
      }
    }
  }

  private generateCurl(url: string, method: ApiMethods, headers: Record<string, string>,
                       body: Record<string, string | Record<string, string>>): string {
    let curl = `curl -X ${method.toUpperCase()} '${url}'\n`;
    if (headers) {
      for (const [key, value] of Object.entries(headers)) {
        curl += `-H '${key}: ${value}'\n`;
      }
    }

    if (body) {
      const formattedBody = JSON.stringify(body, null, 2);
      curl += `-d '${formattedBody}'\n`;
    }

    return curl;
  }

  private generateDefinitions({schemas, requestBodies}: ApiComponentsDTO, services: DocsServiceDTO[],
                              menuPages: MenuOverlaySubOptions<string>[]): void {
    this.processComponentSchemas(schemas, services, menuPages);
    if (requestBodies) {
      this.processComponentRequestBody(requestBodies, services, menuPages);
    }
  }

  private processComponentSchemas(schemas: Record<string, ApiSchemasDTO>, services: DocsServiceDTO[],
                                  menuPages: MenuOverlaySubOptions<string>[]): void {
    for (const [dtoName, {type, properties, required}] of Object.entries(schemas)) {
      const url = `docs/objects/${dtoName}`;
      services[services.length - 1].objects.push({
        type,
        id: dtoName,
        name: dtoName,
        description: '',
        path: url,
        parameters: this.generateSchemaParameters(properties, required || []),
      });
      menuPages[menuPages.length - 1].options.push({
        name: dtoName,
        url,
      });
    }
  }

  private processComponentRequestBody(schemas: Record<string, ApiRequestBodyDTO>, services: DocsServiceDTO[],
                                      menuPages: MenuOverlaySubOptions<string>[]): void {
    for (const [dtoName, {description, content}] of Object.entries(schemas)) {
      const url = `docs/objects/${dtoName}`;
      services[services.length - 1].objects.push({
        type: null,
        id: dtoName,
        name: dtoName,
        description,
        path: url,
        parameters: this.generateRequestParameters(content),
      });
      menuPages[menuPages.length - 1].options.push({
        name: dtoName,
        url,
      });
    }
  }

  private generateSchemaParameters(properties: Record<string, ApiProperties>, required: string[]): DocsObjectParameters[] {
    const parameters: DocsObjectParameters[] = [];
    if (properties) {
      for (const [name, {type, items, description, default: defaultValue, format, examples, enum: values}] of Object.entries(properties)) {
        let objectLink = '';
        if (items) {
          if (items.$ref) {
            objectLink = `docs/objects/${this.getObjectName(items.$ref)}`;
          } else if (items.additionalProperties) {
            objectLink = `docs/objects/${this.getObjectName(items.additionalProperties.$ref)}`;
          }
        }
        parameters.push({
          allowedValues: this.getAllowedValues(type, values || []),
          description,
          defaultValue,
          name,
          format,
          examples,
          required: required.includes(name),
          objectLink,
          type: this.getPropertyType(type, items?.type),
        });
      }
    }
    return parameters;
  }

  private generateRequestParameters(properties: Record<ApiContentTypes, ApiContentDTO>): DocsObjectParameters[] {
    const parameters: DocsObjectParameters[] = [];
    if (properties) {
      for (const [name, {schema}] of Object.entries(properties)) {
        let objectLink = '';
        if (schema.items) {
          if (schema.items.$ref) {
            objectLink = `docs/objects/${this.getObjectName(schema.items.$ref)}`;
          }
        }
        if (schema.$ref) {
          objectLink = `docs/objects/${this.getObjectName(schema.$ref)}`;
        }
        parameters.push({
          allowedValues: this.getAllowedValues(schema.type, []),
          name,
          defaultValue: '',
          description: '',
          examples: [],
          format: null,
          required: false,
          objectLink,
          type: this.getPropertyType(schema?.type, schema?.items?.type),
        });
      }
    }
    return parameters;
  }

  getPropertyType(type: PropertyTypes, secondType: PropertyTypes): PropertyTypes {
    if (secondType) {
      return `${secondType} ${type || ''}` as PropertyTypes;
    }
    return type || null;
  }

  public loadServices(): void {
    this.http.get<ApiServicesDTO>(`${BACKEND_URL}/api/v3/api-docs`).pipe(untilDestroyed(this)).subscribe({
      next: ({components, tags, paths, servers}) => {
        const {menuPages, services} = this.getControllers(tags, servers[0].url);
        menuPages.push({
          options: [],
          title: 'Common objects',
        });
        services.push({
          name: 'Common objects',
          id: 'objects',
          host: null,
          objects: [],
        });
        this.generatePaths(servers[0].url, paths, services, menuPages, components);
        this.generateDefinitions(components, services, menuPages);
        this.services.next(services);
        this.menuPages.next(menuPages);
      },
    });
  }

  private getObjectName($ref: string): string {
    return $ref.split('schemas/')[1];
  }

  private getAllowedValues(type: PropertyTypes, defaultValues: string[]): string[] {
    if (type === 'boolean') {
      return ['true', 'false'];
    }
    return defaultValues;
  }

}
